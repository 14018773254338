import {isBefore, parseISO} from "date-fns"
import i18next from "i18next"

import {
  ConsultationFile,
  DocumentationTabItemConfigType,
  MedicalDocuments,
  MedicalFile,
  TableDocumentationExamResult,
  TableDocumentationFileType,
} from "../Documentation.type"
import { getDocumentIconType } from "../Documentation.utils"
import {ExamsState} from "../../../store/exams/exams.types"

const visibleFileTypes: Array<keyof MedicalDocuments> = [
  "consultation_files",
  "medicine_eprescriptions",
  "recommendations",
  "prescriptions",
  "user_files",
  "recommendations",
  "semen_donor",
  "e_referrals",
  "trans_prescription",
  "consultation_e_referrals"
]

export const getDocumentationItemsConfig = (
  documents: MedicalDocuments,
  examsResultsList: ExamsState["examsWithResults"]["items"] = []
): DocumentationTabItemConfigType[] => {

  const parsedExamsResultsItems: TableDocumentationExamResult[] = (examsResultsList || []).map(examResultItem => ({
    ...examResultItem,
    documentType: "examsResults",
    iconClass: "icon-flask",
    name: `${i18next.t("exam:exam")}${examResultItem.source_presentation ? ` - ${examResultItem.source_presentation}` : ""}`,
    date: examResultItem.created_at,
  }))

  const parsedMedicalDocumentationFiles = Object.entries(documents)
    .reduce((files: TableDocumentationFileType[], [docKey, docValue]) => {
      if ((visibleFileTypes as string[]).includes(docKey)) {
        files = files.concat(docValue.items.map((item: MedicalFile) => {
          // added prefix to ID to make it unique because backend returns the same ID's for recommendations and trans-prescriptions
          return {
            ...item,
            documentType: (item as ConsultationFile).isAddByPatient ? "user_files" : docKey,
            iconClass: getDocumentIconType(docKey as keyof MedicalDocuments, "icon-file"),
          }
        }))
      }
      return files
    }, [])

  const parsedFiles = [...parsedExamsResultsItems, ...parsedMedicalDocumentationFiles]
    .sort((itemA, itemB) => {
      return (itemA.date && itemB.date)
        ? isBefore(parseISO(itemA.date), parseISO(itemB.date)) ? 1 : -1
        : -1
    })

  return [
    {
      label: "allFiles",
      value: "allFiles",
      items: parsedFiles
    },
    {
      label: "prescriptions",
      value: "medicine_eprescriptions",
      items: parsedFiles.filter(file => file.documentType === "medicine_eprescriptions" || file.documentType === "trans_prescription")
    },
    {
      label: "referrals",
      value: "prescriptions", // backend bug name
      items: parsedFiles.filter(file => file.documentType === "prescriptions" || file.documentType === "consultation_e_referrals")
    },
    {
      label: "myFiles",
      value: "user_files",
      items: parsedFiles.filter(file => file.documentType === "user_files")
    },
    {
      label: "recommendations",
      value: "recommendations",
      items: parsedFiles.filter(file => file.documentType === "recommendations")
    },
    {
      label: "examsResults",
      value: "examsResults",
      items: parsedExamsResultsItems || [],
    },
    {
      label: "eReferrals",
      value: "e_referrals",
      items: parsedFiles.filter(file => file.documentType === "e_referrals")
    },
  ]
}
