import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Button, TableCell, Typography, useMediaQuery,useTheme} from "@material-ui/core"

import api from "../../../../api/api"
import useFileDownload from "../../../../hooks/useFileDownload"
import GlobalErrorSnackbar from "../../../commonFormItems/globalErrorSnackbar/GlobalErrorSnackbar"
import IconByIntegrationType from "../../../iconByIntegrationType/IconByIntegrationType"
import {FileExtension, TableDocumentationConsultationFile, TableDocumentationFileType} from "../../Documentation.type"
import ButtonLoader from "../../../common/buttonLoader/ButtonLoader.component"
import TextLabel from "../../../common/textLabel/TextLabel.component"
import {mapDocumentTypeToRequestConfig} from "./DocumentationItem.utils"
import {useDocumentationItemStyles} from "./DocumentationItem.styles"

interface DocumentationItemProps {
  file: TableDocumentationFileType;
  documentType: FileExtension["documentType"];
}

const DocumentationItem: FC<DocumentationItemProps> =({file, documentType}) => {
  const classes = useDocumentationItemStyles()
  const {t} = useTranslation()

  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"))
  const isExamResult = documentType === "examsResults"
  const getDocumentPdf = () => api.request<Blob>({...mapDocumentTypeToRequestConfig(file.id, documentType)})
  const {download, downloading, downloadError} = useFileDownload(getDocumentPdf)

  const handleDownloadPdfClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    (event.target as HTMLInputElement).blur()
    download(`${file.date} ${file?.name?.replace(".", "")}`)
  }

  const fileCreationDate = file.date ? file.date.split(" ")[0] : "" // get "YYYY-MM-DD" from "YYYY-MM-DD HH:MM:SS"
  const parsedFileCreationDate = fileCreationDate.split("-").reverse().join(".")  // get DD.MM.YYYY from "YYYY-MM-DD"

  /* documentation item elements */
  const fileNameBox = (
    <Typography component="span">
      <IconByIntegrationType iconName={file.iconClass} className={classes.fileIcon} />
      { file.name }
    </Typography>
  )

  const consultationSpecializationBox = (
    <Box>
      {(file as TableDocumentationFileType).documentType === "consultation_files"
      && !(file as TableDocumentationConsultationFile).isAddByPatient
      && (file as TableDocumentationConsultationFile)?.specialization_name && (
        <TextLabel
          text={(file as TableDocumentationConsultationFile)?.specialization_name}
          textOverflow
        />
      )}
    </Box>
  )

  const downloadFileButtonBox = (
    <Button
      disabled={downloading}
      color="primary"
      className={classes.downloadButton}
      onClick={handleDownloadPdfClick}
      startIcon={downloading
        ? <ButtonLoader position="prefix"/>
        : (
          <IconByIntegrationType {...{ component: "span", variant: "h4" }}
            iconName={"icon-download"}
            returnTypography={true}
          />
        )
      }
    >
      <Typography variant="body2">
        { t("download") }
      </Typography>

      <GlobalErrorSnackbar isError={!!downloadError}/>
    </Button>
  )

  const fileDateBox = (
    <Box textAlign={isSmUp ? "right" : "left"}>
      {isExamResult &&
          <Typography variant={"body2"} noWrap={true}>
            {t("exam:examOrderDate") }
          </Typography>
      }
      <Typography
        color={isSmUp ? "textPrimary" : "textSecondary"}
        variant={isSmUp ? "body1" : "body2"}
      >
        { parsedFileCreationDate }
      </Typography>
    </Box>
  )
  /* e/o documentation item elements */

  return isSmUp
    ? ( // desktop version
      <>
        <TableCell component="th" scope="file" className={classes.item}>
          { fileNameBox }
        </TableCell>

        <TableCell className={classes.item}>
          { consultationSpecializationBox }
        </TableCell>

        <TableCell>
          { downloadFileButtonBox }
        </TableCell>

        <TableCell>
          { fileDateBox }
        </TableCell>
      </>
    )
    : ( // mobile version
      <TableCell className={classes.mobileWrapper}>
        <Box mb={"8px"}>
          { fileDateBox }
        </Box>

        <Box>
          { fileNameBox }
        </Box>

        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
        >
          { consultationSpecializationBox }
          <Box ml="auto" className="download">
            { downloadFileButtonBox }
          </Box>
        </Box>
      </TableCell>
    )
}

export default DocumentationItem
